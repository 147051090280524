@import "../colors.scss";

.reel {
  position: relative;
  height: 300px;
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.reel-img {
  width: 100%;
  object-fit: contain;
}

.reel-container {
  overflow: hidden;
  position: relative;
  height: 400px;
  width: 100%;
  margin: 20px;
}

.reel-content {
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  // background-color: lightgreen;
  padding: 10px;
  height: 100%;
  width: 100%;
  padding: 0;

}

.reel-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 32%;
  transition: opacity 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
}

.reel-item-desc {
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}

.reel-hide {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.reel-ctrl {
  border: solid white;
  border-width: 0 0.3em 0.3em 0;
  display: inline-block;
  padding: 2%;
  background-color: transparent;
}

.reel-ctrl:hover {
  border: solid $tertiaryColor;
  border-width: 0 0.3em 0.3em 0;
  display: inline-block;
  padding: 2%;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.reel-next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.reel-prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}