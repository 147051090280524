@import "../../components/colors.scss";

.about-container {
    position: relative;
    width: 100%;
    height: 1200px;
    padding-bottom: 10px;
    color: $primaryTextColor;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 200px 400px 100px;
    grid-template-areas:
        "header-1 header-1 header-1"
        "content-1 content-1 content-1"
        "header-2 header-2 header-2"
        "content-2 content-2 content-2"
    ;
}

.about-container .content p {
    background-image: linear-gradient(-33deg, $tertiaryColor 50%, $secondaryColor 50%);
}

.about-container .content-1 {
    width: 75%;
}

.about-container .content-1 p {
    font-size: 20px;
}

.about-container .header-1 {
    grid-area: header-1;
    justify-self: center;
    align-self: end;
}

.about-container .content-1 {
    grid-area: content-1;
    justify-self: center;
}

.about-container .header-2 {
    grid-area: header-2;
    justify-self: center;
}

.about-container .content-2 {
    align-self: start;
    grid-area: content-2;
}