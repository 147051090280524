@import "../colors.scss";

.navbar {
    position: sticky;
    background-color: $primaryColor;
    color: $primaryTextColor;
    top: 0;
    padding: 0;
    margin: 0;
    position: sticky;
    z-index: 1;
    width: 100%;
    height: 55px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, .2);
}

.navbar ul {
    list-style: none;
    padding: 0.8rem;
    margin: 0;
}

.navbar a {
    color: inherit;
    text-decoration: none;
}

.navbar a:hover {
    text-decoration: underline;
}

.navbar ul {
    display: flex;
    justify-content: space-evenly;
}

.hamburger {
    order: 0;
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    cursor: pointer;
    width: 1.5rem;
    height: 0.18rem;
    border-radius: 5px;
    margin: 0px;
    transition: all 300ms ease-in-out;
    background-color: $primaryTextColor;
}

.bar::after,
.bar::before {
    content: '';
    display: block;
    position: absolute;
    width: 1.5rem;
    height: 0.18rem;
    border-radius: 5px;
    margin: 0;
    transition: all 300ms ease-in-out;
    background-color: $primaryTextColor;
}

.bar::before {
    transform: translateY(-0.4rem);
}

.bar::after {
    transform: translateY(0.4rem);
}

@media (max-width:700px) {
    .navbar {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 0;
        margin-right: 30px;
    }

    .hamburger {
        position: relative;
        display: block;
        left: -20px;
        padding: 30px;
        z-index: 1;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 500ms ease-in-out;
    }

    .hamburger.active .bar {
        transform: translateX(-3rem);
        background: transparent;
    }

    .hamburger.active .bar::before {
        transform: translateX(3rem) rotate(45deg);
    }

    .hamburger.active .bar::after {
        transform: translateX(3rem) rotate(-45deg);
    }

    .nav-menu li {
        padding: 0;
        margin: 0;
    }

    .nav-menu {
        position: fixed;
        background-color: inherit;
        margin: 0;
        padding: 0;
        opacity: 0;
        top: 0;
        width: 96%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 300ms ease-in-out;

    }

    .nav-menu.active {
        position: fixed;
        left: 0;
        opacity: 1;
        top: 55px;
        height: auto;
        font-size: inherit;
        border-top: 2px solid white;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, .2);
        transition: all 300ms ease-in-out;
    }

}