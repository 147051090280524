@import "../../components/colors.scss";

#contact {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact h2 {
    margin-bottom: 30px;
}

.contact-menu li {
    list-style: none;
}

.contact-menu {
    position: relative;
    padding: 0;
    margin: 0;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: $secondaryColor;
    border-radius: 15px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(33deg, #15284849 50%, #1b1e28cc 50%);
}

.contact-menu-image {
    background-image: url('../../../public/Images/backgound images/mycode1.png');
    background-size: cover;
    background-position: center;
    position: relative;
    width: 90%;
    border-radius: 15px;
    margin-bottom: 380px;
}

.contact-menu-image::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(1px);
}


.contact-menu-item:nth-child(1) a svg,
.contact-menu-item:nth-child(2) a svg {
    color: white;
}

.contact-menu-item a {
    display: flex;
    align-items: center;
}

.contact-menu-item a svg {
    color: #0368ff;
    height: 40px;
    width: 40px;
    padding: 0;
    margin: 0;
}

.contact-menu-item a svg:hover {
    color: white;
}

.contact-menu-item:nth-child(1) a svg:hover,
.contact-menu-item:nth-child(2) a svg:hover {
    color: #0368ff;
}